import axios from 'axios';
import Places from 'google-places-web';
import { async } from 'regenerator-runtime';

//const corsurl = "https://cors-anywhere.herokuapp.com/"
const corsurl = '';

//export const bserver = 'https://localhost:7214/api/';
export const bserver = '';

export const server = corsurl + 'https://api.yootechnologies.com/api/';
export const imgServer = 'https://api.yootechnologies.com';
export const token =
  '198141d70a3199a54c201643c34b53e6c809cbab40c4c28aa9cd35a6968aea5125449dde46af87e8e0b0962be15ffdf413980c9c0b33ac99b78791ca8a887b1197e914128173979b16456483dc64d2ce10cddd3a5e588023bc956b5b877fbd2904037e6dcad846c64d0c253ab99e8e6cd5e438022aa190b8f5643ef30ad61a3a';

const googleAPIKey = 'AIzaSyBVMvX41R_NPOlPtCFOxKv8pvsw3w_rfFc_';
const placeId = 'ChIJAToILfEVK4gROs8I8ljRF58_';

const googleURL =
  corsurl +
  'https://maps.googleapis.com/maps/api/place/details/json?fields=&place_id=' +
  placeId +
  '&key=' +
  googleAPIKey;

Places.apiKey = googleAPIKey;

export const req = axios.create({
  baseURL: server,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${token == null ? '' : token}`,
    'Content-Type': 'application/json',
  },
});

export const breq = axios.create({
  baseURL: bserver,
  timeout: 100000,
  headers: {
    Authorization: `Bearer ${token == null ? '' : token}`,
    'Content-Type': 'application/json',
  },
});

req.interceptors.response.use(
  (response) => {
    console.log('response from interceptor');
    console.log(response);
    return response;
  },
  (error) => {
    console.log('interceptor response error ');
    console.log(error);
    return error;
  }
);

export const googlePlaceDataAPI = async () => {
  try {
    console.log('calling google API');

    const resp = await axios.get(bserver + 'GoogleService');

    return resp.data.result;
  } catch (error) {
    return null;
  }
};

export const sendBookingConfirmationEmail = async (bookingId) => {
  try {
    console.log('calling booking API');
    const resp = await axios.get(
      bserver + 'booking/SendBookingEmail/' + bookingId
    );
    console.log(
      '🚀 ~ file: config.js:84 ~ sendBookingConfirmationEmail ~ resp:',
      resp
    );
    return resp;
  } catch (error) {
    return null;
  }
};

export const getWebsiteData = async () => {
  const respData = await req(
    `pw-cottages?populate=*&pagination[pageSize]=100`
  ).catch(function (error) {
    console.error(error);
  });
  if (respData) {
    console.log(
      '🚀 ~ file: config.js:96 ~ getWebsiteData ~ respData.data.data:',
      respData.data.data
    );
    return respData.data.data;
  }
};

/* if (sessionStorage.getItem('session_WebsiteData') == null) {
    const respData = await req(
      `pw-cottages?populate=*&pagination[pageSize]=100`
    ).catch(function (error) {
      console.error(error);
    });
    if (respData) {
      sessionStorage.setItem(
        'session_WebsiteData',
        JSON.stringify(respData.data.data)
      );
    }
  }

  
};*/

export const getSettingsData = async () => {
  const respData = await req(`pickwickcottage?fields[0]=admindata`).catch(
    function (error) {
      console.error(error);
    }
  );

  return respData.data.data.attributes.admindata;
};
