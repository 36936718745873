import { async } from 'regenerator-runtime';
import {
  req,
  imgServer,
  googlePlaceDataAPI,
  getSettingsData,
} from '../js/config';

let PageData = {};
let TestimonialData = {};
let selectedValueAdults;
let selectedValueChild = 0;
let CottageSettings = {};

const Initialize = async () => {
  try {
    await getPageData();
    console.log('getPageData', PageData);

    $('#datepicker')
      .datepicker({
        format: 'dd-mm-yyyy',
        autoclose: true,
        beforeShowDay: function (date) {
          var month = date.getMonth();
          if (
            month >= CottageSettings.weeklyBookingMonthStart &&
            month <= CottageSettings.weeklyBookingMonthEnd
          ) {
            // months are 0-based
            return date.getDay() == CottageSettings.defaultCheckinDay; // if Saturday
          }
          return true;
        },
      })
      .on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        minDate.setDate(
          minDate.getDate() + parseInt(CottageSettings.minimumBookingDays)
        );
        //console.log("🚀 ~ file: main.js:234 ~ minDate:", minDate)
        var maxDate = new Date(selected.date.valueOf());

        var selectedMonth = selected.date.getMonth();
        if (
          selectedMonth >= CottageSettings.weeklyBookingMonthStart &&
          selectedMonth <= CottageSettings.weeklyBookingMonthEnd
        ) {
          // months are 0-based
          maxDate.setDate(maxDate.getDate() + 7);
        } else {
          maxDate.setDate(maxDate.getDate() + 7);
        }

        $('#datepickeri').datepicker('setStartDate', minDate);
        $('#datepickeri').datepicker('setEndDate', maxDate);

        $('#datepickeri').datepicker('update', maxDate);
      });

    $('#datepickeri').datepicker({
      format: 'dd-mm-yyyy',
      autoclose: true,
      beforeShowDay: function (date) {
        var month = date.getMonth();
        if (
          month >= CottageSettings.weeklyBookingMonthStart &&
          month <= CottageSettings.weeklyBookingMonthEnd
        ) {
          // months are 0-based
          return date.getDay() == CottageSettings.defaultCheckinDay; // if Saturday
        }
        return true;
      },
    });

    await generatePageHTML();
    await bindPageControls();

    console.log('hide loader');

    setTimeout(() => {
      var loader = document.getElementById('loader-wrapper');
      loader.style.display = 'none';
    }, 1000);
  } catch (err) {
    var loader = document.getElementById('loader-wrapper');
    loader.style.display = 'none';
    console.log('🚀 ~ file: main.js:14 ~ Initialize ~ err', err);
  }
};
const generatePageHTML = async () => {
  await CottageListDataNew();

  await BuildTestimonials();

  await loadJavascriptNow();
};

const bindPageControls = async () => {
  const checkAvailabilityBtn = document.getElementById('availabilityCheck');

  $('#input_noOfAdults li a').on('click', function () {
    selectedValueAdults = $(this).text(); // store the selected value when an item is clicked
  });

  $('#input_noOfChildren li a').on('click', function () {
    selectedValueChild = $(this).text(); // store the selected value when an item is clicked
  });

  checkAvailabilityBtn.addEventListener('click', function () {
    const arrivalDate = document
      .getElementById('input_arrivalDate')
      .value.trim();
    const departureDate = document
      .getElementById('input_departureDate')
      .value.trim();
    const noOfAdults = selectedValueAdults;
    const noOfChildren = selectedValueChild;

    let focusField = null;
    if (!arrivalDate) {
      focusField = document.getElementById('input_arrivalDate');
    } else if (!departureDate) {
      focusField = document.getElementById('input_departureDate');
    } else if (!noOfAdults) {
      focusField = document.getElementById('input_noOfAdults');
    }

    if (focusField) {
      alert('Please fill in all the fields.');
      focusField.focus();
    } else {
      const arrivalDateParts = arrivalDate.split('-');
      const departureDateParts = departureDate.split('-');
      const arrivalDateObj = new Date(
        `${arrivalDateParts[1]}/${arrivalDateParts[0]}/${arrivalDateParts[2]}`
      );
      const departureDateObj = new Date(
        `${departureDateParts[1]}/${departureDateParts[0]}/${departureDateParts[2]}`
      );
      console.log('🚀 ~ file: main.js:79 ~ arrivalDateObj:', arrivalDateObj);

      console.log(
        '🚀 ~ file: main.js:80 ~ departureDateObj:',
        departureDateObj
      );
      if (
        departureDateObj.getFullYear() < arrivalDateObj.getFullYear() ||
        (departureDateObj.getFullYear() === arrivalDateObj.getFullYear() &&
          departureDateObj.getMonth() < arrivalDateObj.getMonth()) ||
        (departureDateObj.getFullYear() === arrivalDateObj.getFullYear() &&
          departureDateObj.getMonth() === arrivalDateObj.getMonth() &&
          departureDateObj.getDate() <= arrivalDateObj.getDate())
      ) {
        alert('Departure date must be greater than arrival date.');
        document.getElementById('input_departureDate').focus();
      } else {
        //const url = `../booking.html?cottageId=${CottageData.attributes.cottageId}&arrivalDate=${arrivalDate}&departureDate=${departureDate}&noOfAdults=${noOfAdults}&noOfChildren=${noOfChildren}`;
        const url = `../cottagesavailability?fromDate=${convertDateFormat(
          arrivalDate
        )}&toDate=${convertDateFormat(
          departureDate
        )}&noOfAdults=${noOfAdults}&noOfChildren=${noOfChildren}`;
        window.location.href = url;
        //alert('redirect')
      }
    }
  });
};

const convertDateFormat = (inputDate) => {
  // Split the input string on dash character
  var splitDate = inputDate.split('-');

  // Assemble and return the date in 'yyyy-mm-dd' format
  return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
};

const CottageListDataNew = async () => {
  let tpl = '';

  console.log(
    '🚀 ~ file: main.js:29 ~ CottageListDataNew ~ PageData.length',
    PageData.length
  );

  for (let i = 0; i < PageData.length; i += 6) {
    // console.log("🚀 ~ file: main.js:31 ~ CottageListDataNew ~ i", i)

    //console.log("🚀 ~ file: main.js:35 ~ CottageListDataNew ~ (i + 6 > PageData.length ? i + (i + 6 - PageData.length)", i, (i + 6 > PageData.length ? i + (PageData.length - i) : i + 6));

    tpl += `<div class="item ">
                    
        ${await CottageListData(
          PageData.slice(
            i,
            i + 6 > PageData.length ? i + (PageData.length - i) : i + 6
          )
        )}
                   
                </div>`;
  }

  document.querySelector('#rooms').innerHTML = tpl;
};

const generateInlineSlider = (x, d) => {
  // Check if 'small' is defined, otherwise use 'x.attributes.url'
  let imageUrl = d.attributes.formats.small
    ? imgServer + d.attributes.formats.small.url
    : x.attributes.url;
  let imageAlt = d.attributes.formats.small
    ? d.attributes.formats.small.name
    : 'Cottage';
  let imageTitle = d.attributes.formats.small
    ? d.attributes.formats.small.name
    : 'Cottage';

  let tpl = `<div class="swiper-slide">
    <img src="${imageUrl}" class="img-responsive" alt="${imageAlt}" title="${imageTitle}">
  </div>`;
  return tpl;
};

const generateInlineSliders = (x) => {
  let tpl = ``;

  if (x.attributes.images.data.length > 0) {
    tpl += generateInlineSlider(x, x.attributes.images.data[0]); // Directly access the first image
  }

  return tpl;
};

const CottageListData = async (d) => {
  // console.log("🚀 ~ file: main.js:43 ~ CottageListData ~ d", d)

  let tpl1 = '';

  d.forEach((x) => {
    tpl1 += `<div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 ">
                    <div class="wrap-box">
                        <a href="/cottagedetail/${x.id}">
                            <div class="box-img">
                                <div class="swiper mySwiper">
                                    <div class="swiper-wrapper">   
                                        ${generateInlineSliders(
                                          x
                                        )};                            
                                    </div>
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                </div>
                            </div>
                        </a>
                        <div class="rooms-content">
                            <a href="/cottagedetail/${x.id}" >  
                                <h4 class="sky-h4">${x.attributes.name}</h4>
                                <p class="price">$${
                                  x.attributes.rate
                                }/ Per Night</p>
                            </a>
                        </div>
                    </div>
                </div>`;
  });

  return tpl1;
};

const BuildTestimonials = async () => {
  console.log(
    '🚀 ~ file: main.js:72 ~ BuildTestimonials ~ testimonialData:',
    TestimonialData
  );
  try {
    //WHAT OUR CUSTOMERS SAY ON GOOGLE
    let tpl = '';
    TestimonialData.reviews.forEach((t) => {
      tpl += `<div class="item ">
                      <a href="${t.author_url}"> 
                          <div class="img-testimonials"><img src="${t.profile_photo_url}"    alt="#">
                          </div>
                      </a>
                      <p class="testimonials-p"><span>“</span>​‌ ${t.text}</p>
                      <h5 class="sky-h5">${t.author_name}</h5>
                      <p class="testimonials-p1">${t.relative_time_description}</p>
                  </div>`;
    });
    document.querySelector('#testimonials').innerHTML = tpl;
  } catch (error) {
    console.log('🚀 ~ file: main.js:285 ~ BuildTestimonials ~ error:', error);
  }
};

const getPageData = async () => {
  PageData = window.__COTTAGE_DATA__;

  PageData = PageData.Result;

  CottageSettings = await getSettingsData();
  console.log(
    '🚀 ~ file: main.js:295 ~ getPageData ~ CottageSettings:',
    CottageSettings
  );

  TestimonialData = await googlePlaceDataAPI();
  console.log(
    '🚀 ~ file: main.js:297 ~ getPageData ~ TestimonialData:',
    TestimonialData
  );
};

const loadJavascriptNow = async () => {
  const script = document.createElement('script');
  script.src = 'js/sky.js';

  // Append to the `head` element
  document.head.appendChild(script);
  var swiper = new Swiper('.mySwiper', {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
};

Initialize();
